/*
 * @Description: 
 * @Author: lja
 * @Date: 2021-10-8 08:33:56
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:11:31
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//查询单条内容详情
export const GetChatSysMsg=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Chat/ChatSysMsg/Get`,params)
} 
//分页查询内容详情
export const GetChatSysMsgPage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Chat/ChatSysMsg/GetPage`,params)
}
//添加内容详情
export const AddChatSysMsg=(params)=>{
    return request.apiPost(`/api${API_VERSION}/Chat/ChatSysMsg/Add`,params)
} 
//删除内容详情
export const DeleteChatSysMsg=(params)=>{
    return request.apiDelete(`/api${API_VERSION}/Chat/ChatSysMsg/Delete?msgId=${params}`)
} 
//批量删除内容详情
export const BatchDeleteChatSysMsg=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Chat/ChatSysMsg/BatchDelete`,params)
}