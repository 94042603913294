<!--
 * @Description: 
 * @Author: lja
 * @Date: 2021-09-18 10:47:11
 * @LastEditors: weig
 * @LastEditTime: 2022-02-23 12:20:31
-->
<template>
  <div>
    <!-- 面包屑 begin -->
    <eup-crumbs icon="Comment" firstCrumbs="消息管理" secondCrumbs="消息管理" />
    <!-- 面包屑 end -->
    <!-- 内容区域 begin -->
    <!-- 表单内容 -->
    <div class="container">
      <!-- 查询 -->
      <div class="handle-box">
          <el-form :model="state.query" :inline="true" v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:getpage`,`api${state.VIEW_VERSION}:chat:chatsysmsg:add`,`api${state.VIEW_VERSION}chat:chatsysmsg:batchdelete`])" >
             <template v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:getpage`])">
                  <el-form-item label="消息状态">
                  <el-select v-model="state.query.msgStatus" placeholder="请选择" class="handle-select mr10"  @change="handleSearch">
                  <el-option
                   v-for="item in state.msgStatus"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
                    <el-form-item label="消息类型">
                  <el-select v-model="state.query.msgType" placeholder="请选择" class="handle-select mr10" @change="handleSearch">
                  <el-option
                   v-for="item in state.msgType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                      >
                    </el-option>
                     </el-select>
                   </el-form-item>
               <el-form-item  label="消息标题">
                  <el-input v-model="state.query.msgTitle" placeholder="消息标题"  @change="handleSearch">
                    <template #prefix>
                      <i class="el-input__icon el-icon-search" />
                    </template>
                  </el-input>
              </el-form-item>
              <el-form-item style="margin-right:10px;">
                <el-button type="primary" icon="Search" @click="handleSearch">查询</el-button>
              </el-form-item>
               </template>
              <el-form-item style="margin-right:10px;" v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:add`])">
                <el-button type="primary" icon="Plus" @click="handleClickAddData">新增</el-button>
              </el-form-item>
              <el-form-item style="margin-right:10px;">
              <el-button
                  type="danger"
                  icon="Delete"
                  class="handle-del mr10"
                  @click="handleClickBatchDelete"
                  v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:batchdelete`])"
                  >批量删除
              </el-button>
              </el-form-item>
          </el-form>
      </div>

      <!-- 列表 -->
      <el-table
          border
          highlight-current-row
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          v-loading="state.loading"
          :data="state.tableData"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
      <el-table-column prop="num" label="序号" width="60" align="center"></el-table-column>
      <el-table-column prop="nickName" label="用户昵称"  min-width="120"  align="center" ></el-table-column> 
         <el-table-column prop="msgTitle" label="消息标题"  min-width="180"  align="center" ></el-table-column>
         <el-table-column prop="msgType" label="消息类型" min-width="80"  align="center" :formatter="msgType" ></el-table-column>
         <el-table-column prop="msgStatus" label="消息状态" min-width="90"  align="center" >
           <template #default="{row}">
                  <el-tag :type="row.msgStatus == 1 ? 'danger' : 'success'" disable-transitions>
                      {{ row.msgStatus == 1 ? '未读' : '已读' }}
                  </el-tag>
              </template>
         </el-table-column>
        <el-table-column prop="sendDate" label="发布日期" min-width="150" align="center" ></el-table-column>
          <el-table-column label="操作" min-width="150" align="center" fixed="right" v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:getpage`])" >
                  <template #default="{ row }">
                     <el-button
                        type="text"
                        icon="Check"
                        @click="handleEdit(row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:get`])"
                    >查看详情</el-button>
                       <el-button
                        type="text"
                        icon="Delete"
                        class="red"
                        @click="handleClickDelete(row)"
                        v-if="checkPermission([`api${state.VIEW_VERSION}:chat:chatsysmsg:delete`])"
                    >删除</el-button>
                </template>
          </el-table-column>
      </el-table>
      <!-- 分页 begin-->
       <EupPagination
          :current-page="state.pageIndex"
          :pagesizes="[10,20,50,100]"
          :pagesize="state.pageSize"
          :total="state.pageTotal"
          @getPageData="datasource"
          @resPageData="resPageData">
      </EupPagination>
      <!-- 分页 end-->
    </div>
     <!-- 内容区域 end -->
    <!-- 添加/编辑窗口 begin -->
    <el-dialog 
      :title="state.dialogTitle"
      v-model="state.addDialogFormVisible"
      width="70%"
      @close="closeEditForm()"
      :fullscreen="state.dialogFull"
      >
      <el-form
        ref="addForm"
        :model="state.form"
        :rules="state.addFormRules"
        label-width="80px"
        :inline="false"
      >
      <div v-if="state.isadd">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
       <el-form-item label="标题" prop="msgTitle">
          <el-input v-model="state.form.msgTitle" auto-complete="off"/>
        </el-form-item>
        </el-col>
      
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <el-form-item label="封面图片" prop="msgCover">
            <Eup-Cropper @resUploadData="resUploadData" @defaultSrc="deleteImg"  :zindex="10100" :src="state.form.msgCoverUrl" :aspectRatio="7.5/2"></Eup-Cropper>
          </el-form-item>
         </el-col>
       <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
           <el-row>
      <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
           <el-form-item>
               <el-radio-group v-model="userradio" size="large">
        <el-radio-button label="全部用户" @click="whole"></el-radio-button>
        <el-radio-button label="个别用户" @click="individual"></el-radio-button>
        </el-radio-group>
         </el-form-item>
           </el-col>
                <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
            <el-form-item label="用户" prop="userId" v-if="state.userSelect" >
 <el-select v-model="state.form.userId" multiple="true"  class="m-2" placeholder="请选择" size="large">
         <el-input v-model="state.userquery.key" :clearable="true" placeholder="邮箱或手机号" >
             <template #append>
                <el-button icon="Search" @click="UserList" />
          </template>
         </el-input>
       <el-option
       v-for="item in state.userlist"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
      >
      </el-option>
  </el-select>
    </el-form-item>
     </el-col>
      </el-row>
        </el-col>
     </el-row>
      <el-row>
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
               <el-form-item label="消息摘要" prop="msgAbstract">
          <el-input v-model="state.form.msgAbstract" auto-complete="off"/>
        </el-form-item>
         </el-col>
     </el-row>
         <el-row>
             <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
         <el-form-item label="消息类型" prop="msgType">    
                  <el-select v-model="state.form.msgType" placeholder="消息类型">
                  <el-option
                   v-for="item in state.optionmsgType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                   :disabled="item.disabled"
                      >
                    </el-option>
                     </el-select>
              </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="18" :lg="18" :xl="18">
           <el-form-item label="链接" prop="msgUrl">
              <el-switch
            v-model="isRouter"
             @change="msgUrlType"
            inline-prompt
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="内部"
            inactive-text="外部"
           >
              </el-switch>
    <el-input
      v-model="state.form.msgUrl"
      autosize
       type="textarea"
      placeholder="消息链接"
      class="input-with-select"
    >
    </el-input>
     </el-form-item>
        </el-col>
         </el-row>
      </div>
        <div v-if="state.isdetails">
         <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
       <el-form-item label="标题" prop="msgTitle">
          <el-input :readonly="true" v-model="state.form.msgTitle" auto-complete="off"/>
        </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="state.form.msgCoverUrl=='' ? false:true">
        <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <el-form-item label="封面图片" prop="msgCover">
            <el-image
        style="width: 350px; height: 150px"
        :src="state.form.msgCoverUrl"
        ></el-image>
          </el-form-item>
         </el-col>
     </el-row>
     <el-row>
         <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
         <el-form-item label="用户昵称" prop="nickName">
            <el-input :readonly="true" v-model="state.form.nickName" auto-complete="off"/>
    </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <el-form-item label="发布日期" prop="sendDate">
            <el-input :readonly="true" v-model="state.form.sendDate" auto-complete="off"/>
    </el-form-item>
        </el-col>
     </el-row>
     <el-row>
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
               <el-form-item label="消息摘要" prop="msgAbstract">
          <el-input :readonly="true" v-model="state.form.msgAbstract" auto-complete="off"/>
        </el-form-item>
         </el-col>
     </el-row>
         <el-row>
             <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="6">
         <el-form-item label="消息类型" prop="msgType">    
                  <el-select :readonly="true" v-model="state.form.msgType" placeholder="消息类型" :disabled="true">
                  <el-option
                   v-for="item in state.optionmsgType"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                   :disabled="item.disabled"
                      >
                    </el-option>
                     </el-select>
              </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="15" :lg="15" :xl="15">
       <el-form-item label="消息链接" prop="msgUrl">
          <el-input :readonly="true" v-model="state.form.msgUrl" auto-complete="off"/>
        </el-form-item>
        </el-col>
         </el-row>

        </div>
        <div v-if="state.isEditor">
          <el-row >
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
         <WangEditor :contant="state.form.msgContent" :disable="true"  @updateContent="updateContent"></WangEditor>
         </el-col>
         </el-row>
        </div>
         <div  v-if="state.isEditor2">
          <el-row >
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
         <WangEditor :contant="state.form.msgContent"  @updateContent="updateContent"></WangEditor>
         </el-col>
         </el-row>
        </div>
      </el-form> 
      <template #title>
        <div class="avue-crud__dialog__header">
            <span class="el-dialog__title">
            <span style="display:inline-block;width:3px;height:20px;margin-right:5px; float: left;margin-top:2px"></span>
              {{state.dialogTitle}}
            </span>
       <div class="avue-crud__dialog__menu" @click="state.dialogFull? state.dialogFull=false: state.dialogFull=true">
            <el-icon v-if="!state.dialogFull" title="全屏"><FullScreen/></el-icon>
            <el-icon v-else title="缩小"><CopyDocument/></el-icon>
          </div> 
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer" v-if="state.isadd">
          <el-button @click="closeEditForm()">取 消</el-button>
          <el-button type="primary" @click="addDialogFormSave()">确 定</el-button>
        </span>
         <span class="dialog-footer" v-if="state.isdetails">
          <el-button @click="closeEditForm()">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, ref,  } from 'vue'
import { ElMessage,ElMessageBox } from 'element-plus'
import {elConfirmDialog, IsNullOrEmpty} from "@/common/js/comm"
import { GetChatSysMsg,GetChatSysMsgPage,AddChatSysMsg,DeleteChatSysMsg,BatchDeleteChatSysMsg} from '@/serviceApi/chat/chatSysMsg.js'
import {useStore} from 'vuex'
import {getUserInfo} from '@/serviceApi/permission/userRole'
import WangEditor from '@/components/eup-editor/WangEditor.vue'
import EupPagination from "../../../components/EupPagination.vue"
import EupCrumbs from "../../../components/eup-crumbs/index.vue"
import EnumConfig from "@/enum/EnumConfig"
import EupCropper from '@/components/eup-uploadPhoto/eup-cropper.vue'

var CURR_VIEW_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `:S:${CURR_VIEW_VERSION}`;
export default {
  name: 'ChatSysMsg',
  components:{
    EupPagination: EupPagination,
    EupCrumbs: EupCrumbs,
     WangEditor: WangEditor,
     EupCropper: EupCropper,
  },
  
  setup() {
    const multipleTable = ref(null);
    const addForm = ref(null);
    const userradio = ref('全部用户')
    const isRouter = ref(false)
    const state = reactive({
      VIEW_VERSION: VIEW_VERSION,
      query: {
           msgTitle:'',
           msgStatus:'0',
           msgType:'0',
        },  
        userquery:{
            key:"",
        },
         multipleSelection: [],                                                                                                                                                                      
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
        tableData: [],
        isdetails:true,
        isadd:true,
        isEditor:false,
        isEditor2:false,
        form: {
            msgType:1,
            msgTitle: "",
            msgAbstract:"",
            msgContent: "",
            msgUrl:"",
            userId:[],
            sendTag:2,
            msgId:"",
            msgCover:"",
            msgCoverUrl:"",
        },
        userlist:[
        ],
        isMsgUrlType:false,
        userlistnull:[
        { value:'001',
         label:'请填写完整的邮箱或手机号',
          disabled:true,
          }
        ],
         urlType: [
              { value: '0', label: '外部' },
              { value: '1', label: '内部' },
            ],
        userSelect:false,
        addFormRules:{
            msgTitle: [{ required: true, message: '请输入标题', trigger: 'blur'},
            {max:50, message: '标题长度不能超过50字', trigger: 'blur'}],
            msgType: [{ required: true, message: '请选择类型', trigger: 'change' }],
            msgAbstract: [{ required: true, message: '请输入摘要', trigger: 'blur'}],
            msgContent: [{ required: true, message: '请输入内容', trigger: ['blur'] }],
        },
        msgStatus:{
           yes: 2,
          no: 1
        },
       optionmsgType:[
        { value: 1, label:'系统'},
        { value: 2, label:'订单'},
       ],
        msgStatus:[
        {value:'0',label:'全部'},
        { value:'1',label:'未读'},
        { value:'2',label:'已读'},
       ],

        msgType:[
        {value:'0',label:'全部'},
        { value:'1',label:'系统'},
        { value:'2',label:'订单'},
       ],
        loading: false,
        store: {}, //vuex全局状态管理对象
        addDialogFormVisible: false, //是否显示对话框
        detailsVisible:false,
        dialogTitle: "", //对话框标题
        dialogFull: false, //是否为全屏 Dialog
        expandRowKeys: [],
        modules: [],
        sels: [], // 列表选中列
    });
    const msgType = (row) => {
         if (row.msgType==1) {
           return "系统"
         }else{
           return "订单"
         }
    }
    const whole = () => {
       state.userSelect=false;
       state.form.sendTag=2;
       state.form.userId="";
    };
   const individual = () => {
       state.userSelect=true
       state.form.userId="";
       state.form.sendTag=1;
       state.userlist=state.userlistnull;
    }
     const msgUrlType = (val,row) => {
       if(val==false){
         state.isMsgUrlType=false
         state.form.msgUrl=""
       }
       else{
          state.isMsgUrlType=true
         state.form.msgUrl=JSON.stringify({"Type":"router","View":"路由名称","Params":{"键":"值"}});
       }
    };
   /** @description 同步富文本内容
     * @author lja
     */
    const updateContent = (val) => {
     state.form.msgContent = val;
  }
    onMounted(() => {
      datasource();
      state.store = useStore();
    });

    /**
     * @description 获取表单数据
     * @author lja
     */
    const datasource = () => {
       let params = {
          "currentPage": state.pageIndex,
          "pageSize": state.pageSize,
          "Filter.MsgTitle":state.query.msgTitle,
          "Filter.MsgStatus":state.query.msgStatus=='0'? null:state.query.msgStatus,
          "Filter.MsgType":state.query.msgType=='0'? null:state.query.msgType,
            }
      state.loading = true;
     GetChatSysMsgPage(params).then(function(res){
        if (res.code == 1) {
            state.pageTotal = res.data.total;//初始化列表数据总数
          let list = JSON.parse(JSON.stringify(res.data.list));
           state.tableData = list;
          state.tableData.forEach((data, i) => {
                    data.num = i + 1;
                });
        } else {
          ElMessage.error(res.msg);
        }
        state.multipleSelection= [];
       state.loading = false;
      })
      .catch(function (err) {
          ElMessage.error("调用接口失败!," + err); 
          state.loading = false;
      });
    }
    /**
     * @description 子组件返回分页数据
     * @author lja
     * @param {Object} obj
     */
    const resPageData = (obj) =>{
     state.pageIndex = obj.currPage;
      state.pageSize = obj.pageSize;
    }
    /**
     * @description 改变查询条件
     * @author lja
     * @param {String} val 值
     */
    const handleChangeQuery = (val)=>{
      //刷新列表
      datasource();
    }

    /**
     * @description 查询
     * @author lja
     */
    const handleSearch =()=>{
        state.pageIndex = 1
        datasource();
    }

     /**
     * @description 查询用户列表
     * @author lja
     */
   const UserList = () => {
       if (state.userquery.key=="") {
           state.userlist=state.userlistnull;
       }
     else{
      getUserInfo(state.userquery.key).then(function(res) {
        if (res.code == 1) {
          if (res.data.user.length>0) {
             let list = JSON.parse(JSON.stringify(res.data.user));
           //将articleTypeName字段名改为label，便于联级框使用
            var a=[];
            var a2=[];
               var b=[];
               for(var i = 0; i < list.length; i++){
              a.push(list[i].userId);
              a2.push(list[i].userName);
              var obj = {
                value: list[i].userId,
                label:list[i].userName,
                };
                b.push(obj);
                }
                 state.userlist = b;
          }
          else{
            state.userlist= state.userlistnull;
          }
        } else {
          ElMessage.error(res.msg);
        }
      });
       }
    };
    /**
     * @description 新增
     * @author lja
     */
    const handleClickAddData = () =>{
      state.form.msgContent="";
      state.form.msgCoverUrl="";
      state.form.msgCover="";
      state.addDialogFormVisible = true;
      state.dialogTitle = "新增";
      state.isadd=true
      state.isEditor=false;
      state.isEditor2=true;
      state.isdetails=false;
    }
    /**
     * @description 批量删除
     * @author lja
     */
    const handleClickBatchDelete = () =>{
       if (state.multipleSelection.length == 0) {
        return ElMessage.error("请选择要删除的数据");
      }
      elConfirmDialog(
        ElMessageBox,
        "此操作将永久删除该数据, 是否继续?",
        "提示",
        "",
        () => {
          BatchDeleteChatSysMsg(state.multipleSelection)
            .then((res) => {
              if (res.code == 1) {
                ElMessage.success("删除成功");
                datasource();
              } else {
                ElMessage.error("删除失败!");
              }
            })
            .catch((err) => {
              ElMessage.error(err.msg);
            });
        },
        () => {
          ElMessage.info("取消删除!");
        }
      );
    }
     const handleSelectionChange = (val) => {
      state.multipleSelection = val.map((s) => {
        return s.msgId;
      });
    };
    /**
     * @description 查看详情
     * @author lja
     * @param {Number} index 行号
     * @param {Object} row 行数据
     */
    const handleEdit =(row)=>{
       state.form.msgContent="";
      state.form.msgCoverUrl="";
      state.form.msgCover="";
      state.addDialogFormVisible = true;
      state.isEditor=true;
      state.isEditor2=false;
      state.dialogTitle = "查看详情";
      state.isdetails=true
      state.isadd=false;
       let params={
         'msgId':row.msgId
       }
     GetChatSysMsg(params).then((res) => {
      if (res && res.code == 1){
        const data = res.data;
        var Base64 = require('js-base64').Base64;
        var msgContent= Base64.decode(data.msgContent);
        state.form.msgContent = msgContent;
        state.form.sendDate=data.sendDate;
        state.form.msgType = data.msgType;
        state.form.msgTitle=data.msgTitle;
        state.form.msgId=row.msgId;
        state.form.msgCover=data.msgCover;
        state.form.msgCoverUrl=data.msgCoverUrl;
        state.form.msgUrl=data.msgUrl;
        state.form.userId=data.userId;
        state.form.nickName=data.nickName;
        state.form.msgAbstract=Base64.decode(data.msgAbstract);
        ++state.addFormKey;
      } else {
        ElMessage.error(res.msg)
      }})
    }

    /**
     * @description 删除
     * @author lja
     * @param {Object} row 行数据
     */
    const handleClickDelete = (row) =>{
       var id = row.msgId;
      elConfirmDialog(ElMessageBox,'此操作将永久删除该数据, 是否继续?','提示', '', ()=>{
        DeleteChatSysMsg(id).then(res =>{
          if(res.code == 1){
              ElMessage.success("删除成功!");
              datasource();
          } else {
              ElMessage.error(res.msg);
          }
        });
      },);
    }

    /**
     * @description 确认保存
     * @author lja
     */
    const addDialogFormSave = ()=>{
       if(IsNullOrEmpty(state.form.msgTitle)){
        ElMessage.error("标题不能为空!");
        return;
      }
    if(IsNullOrEmpty(state.form.msgAbstract)){
        ElMessage.error("摘要不能为空!");
        return;
      } 
       if(IsNullOrEmpty(state.form.msgContent || state.form.msgUrl)){
        ElMessage.error("内容和链接必填一个");
        return;
      }
      if(state.form.sendTag==1){
         if(IsNullOrEmpty(state.form.userId)){
           ElMessage.error("用户不能为空!");
           return
         }
      } 
      let params = {};
         var Base64 = require('js-base64').Base64;
              var msgAbstract=Base64.encode(state.form.msgAbstract)
              var msgContent= Base64.encode(state.form.msgContent);
             var userIds=""
             if (state.form.userId.length>0) {
             var userIds =state.form.userId.join(",");
             }else{
                var userIds ="";
             }
              var url=""
              //内外部链接处理
             if(state.isMsgUrlType){
               url =state.form.msgUrl;
             }else {
               if(state.form.msgUrl!="")
              url= JSON.stringify({"Type":"url","View":state.form.msgUrl,"Params":null});
              else{
                url=null;
              }
             }
              params = {
              "msgType": state.form.msgType,
              "msgCover": state.form.msgCover,
              "msgAbstract":msgAbstract,
              "msgContent":msgContent,
              "msgUrl": url,
              "msgTitle": state.form.msgTitle,
              "userId":userIds,
              "sendTag":state.form.sendTag,
              }
                 AddChatSysMsg(params).then(res =>{
                  if(res.code == 1){
                      ElMessage.success("添加成功");
                      state.addDialogFormVisible=false;
                      datasource();
                  } else {
                      ElMessage.error(res.msg);
                  }
              });
    }

 /**
     * @description 上传文件后返回来的数据
     * @author weig
     * @param {Object} res 服务端成功返回数据
     */
    const resUploadData =(res)=>{
      state.form.msgCover = res.data.eurl;
      state.form.msgCoverUrl = res.data.url;
    }

     /**
     * @description 删除图片
     * @author weig
     * @param {String} data 地址
     */
    const deleteImg = (data)=>{
      state.form.msgCover = data;
      state.form.msgCoverUrl = data;
    }
   /**
     * @description 关闭对话框
     * @author lja
     */
    const closeEditForm = ()=>{
        state.addDialogFormVisible = false;
        state.detailsVisible = false;
        state.isadd=false,
        state.isdetails=false;
         addForm.value.resetFields();//清空表单数据
        ++state.addFormKey;
        userradio.value="全部用户"
        state.userlist=[];
        whole();
        isRouter.value=false
        state.userquery.key="";
        state.form={
            msgType:1,
            msgTitle: "",
            msgContent: "",
            msgAbstract:"",
            sendTag:2,
            msgUrl:"",
            userId:"", 
            msgCover:"",
            msgCoverUrl:"",
        };
    }
    return {
      state,
      handleSearch,
      handleClickAddData,
      handleClickBatchDelete,
      handleEdit,
      handleClickDelete,
      datasource,
      handleChangeQuery,
      multipleTable,
      addForm,
      closeEditForm,
      msgType,
      resUploadData,
      addDialogFormSave,
      resPageData,
      updateContent,
      UserList,
      handleSelectionChange,
      deleteImg,
      userradio,
      whole,
      individual,
      isRouter,
      msgUrlType
  }
}
}

</script>
<style>
</style>